import React from "react"
import "../components/FestPages/FestContainers.scss"
import Fest14Header from "../components/FestPages/14Festival/14FestHeader"
import FestSection from "../components/FestPages/FestSection"
import FestFooter from "../components/FestPages/FestFooter"

const Fest14Page = ({ pageContext: { page, lang } }) => {
  const chapter = require(`../content/${
    lang !== undefined ? lang : ""
  }festivals/14-festival/chapters/${page.title}.json`)
  const socials = require(`../content/${
    lang !== undefined ? lang : ""
  }festivals/common/socials.json`)
  const footer = require(`../content/${
    lang !== undefined ? lang : ""
  }festivals/14-festival/footer.json`)
  return (
    <>
      <Fest14Header page={page} />
      <main className="fest-main">
        <FestSection data={chapter} />
      </main>
      <FestFooter socials={socials} footer={footer} />
    </>
  )
}

export default Fest14Page
